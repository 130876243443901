<app-asc-dialog-wrapper type="default">
  <ng-container dialogTitle>Edit Device Type {{ data.deviceType.deviceType }} </ng-container>

  <div class="dialog-content">
    <mat-checkbox [formControl]="formGroup.controls.sMaxMatch">SMAX match</mat-checkbox>
    <mat-checkbox [formControl]="formGroup.controls.updateDhs">Update DHS</mat-checkbox>
    <mat-checkbox [formControl]="formGroup.controls.unpackArchives">Unpack Archives</mat-checkbox>
    <app-asc-text-input [control]="formGroup.controls.specialProcessingPattern" label="Protected archive regex" placeholder="Regex for protected archive..."
        errorMessage="Enter a valid regex"></app-asc-text-input>

    <h4>Remote Session Types</h4>
    <mat-checkbox style="padding-left: 10px;" [formControl]="formGroup.controls.gateKeeper">Gate Keeper Connection</mat-checkbox>
    <mat-checkbox style="padding-left: 10px;" [formControl]="formGroup.controls.quickConnection">Quick Connection</mat-checkbox>
  </div>

  <app-asc-button class="action-button" dialogActionButton (btnClicked)="actionButtonClicked()"
    [disabled]="formGroup.invalid || loading || formGroup.pristine">Save</app-asc-button>
  <app-asc-button class="action-button" dialogCancelButton type="cancel"
    (btnClicked)="closeButtonClicked()">Cancel</app-asc-button>
</app-asc-dialog-wrapper>