<div class="c-page-heading-text">
  <p class="c-page-heading-text-element-list">Device List</p>
</div>
<div></div>
<div class="devices-list-container">
  <div class="device-list-functions-wrapper">
    <app-asc-text-input class="devices-table" [control]="searchTextControl" [clear]="true" placeholder="Search"
      prefixIcon="search" (keyupEnter)="searchDevices()" (cleared)="loadData()"
      appAscTooltip="Text search in all device data."></app-asc-text-input>

    <div class="horizontal-spacer"></div>

    <app-asc-text-input class="devices-table" [control]="searchSerialNumberControl" [clear]="true"
      placeholder="Filter by Serial Numbers" prefixIcon="filter_1" (keyupEnter)="searchDevices()"
      (cleared)="loadData()" appAscTooltip="Filter by Serial Numbers."></app-asc-text-input>

    <span class="flexer"></span>

    <app-asc-inline-button (click)="provisionDevice()">
      Provision Device
      <mat-icon inlineIcon>add</mat-icon>
    </app-asc-inline-button>
  </div>

  <app-asc-configurable-table class="devices-table" [dataSource]="dataSource" [sortProps]="sort" [pageables]="pageables"
    (sortChange)="sortChange($event)" (paginatorChange)="paginatorListener($event)" [columns]="tableColumns"
    [columnCells]="[deviceTypeTemp, deviceSnTemp, deviceStatusTemp, deviceConnectedTemp, deviceTrainingDevice, deviceOptionstemp]"
    [expandable]="tableExpand"></app-asc-configurable-table>
</div>

<ng-template #deviceTypeTemp let-tableElem="tableElem">
  <div style="min-width: 32px; margin-right: 10px">
    <mat-icon *ngIf="tableElem.parentDeviceId" style="color: grey" [appAscTooltip]="'Satellite Device'"> satellite_alt
    </mat-icon>
  </div>
  {{ tableElem.deviceType }}
</ng-template>

<ng-template #deviceSnTemp let-tableElem="tableElem">
  <div [appClipboardCopy]="tableElem.serialNumber">{{ tableElem.serialNumber }}</div>
</ng-template>

<ng-template #deviceStatusTemp let-tableElem="tableElem">
  <ng-container *ngIf="tableElem | deviceOnlineOffline as offOn">
    <mat-icon [ngStyle]="{ color: offOn.color, marginRight: '8px' }" [appAscTooltip]="offOn.label">{{ offOn.icon
      }}</mat-icon>
  </ng-container>
  {{ tableElem.status }}
</ng-template>

<ng-template #deviceConnectedTemp let-tableElem="tableElem">
  {{ tableElem.lastConnected | date : 'yyyy-MM-dd, HH:mm:ss' }}
</ng-template>

<ng-template #deviceTrainingDevice let-tableElem="tableElem">
  <mat-icon [ngStyle]="{ color: tableElem.trainingDevice ? 'green' : '#236fb8' }">{{
    tableElem.trainingDevice ? 'check_circle' : 'cancel'
    }}</mat-icon>
</ng-template>

<ng-template #deviceOptionstemp let-tableElem="tableElem">
  <app-asc-inline-button (btnClicked)="toDetails(tableElem)">Details <mat-icon inlineIcon>arrow_forward</mat-icon>
  </app-asc-inline-button>
  <app-device-actions [device]="tableElem"></app-device-actions>
</ng-template>

<ng-template #tableExpand let-tableElem="tableElem">
  <div class="device-details">
    <ng-container *ngIf="tableElem | deviceId as idProps">
      <div class="details-col">
        <div>ID:</div>
        <div *ngIf="idProps.hardwareId">Hardware ID:</div>
        <div *ngIf="idProps.regIdNeeded">Registration ID:</div>
        <div *ngIf="idProps.parentId">Parent ID:</div>
      </div>
      <div class="details-col">
        <div [appClipboardCopy]="tableElem.id">{{ tableElem.id }}</div>
        <div [appClipboardCopy]="idProps.hardwareId" *ngIf="idProps.hardwareId">{{ idProps.hardwareId }}</div>
        <div [appClipboardCopy]="idProps.registrationId" *ngIf="idProps.regIdNeeded">
          {{ idProps.registrationId }} {{ idProps.expired ? '(expired)' : '' }}
        </div>
        <div [appClipboardCopy]="idProps.parentId" *ngIf="idProps.parentId">{{ idProps.parentId }}</div>
      </div>
      <div class="vertical-divider"></div>
      <div class="details-col">
        <div>Update DHS:</div>
        <div>Allows Remote Access:</div>
        <div>Training Device:</div>
      </div>
      <div class="details-col">
        <mat-icon [ngStyle]="{ color: tableElem.updateDHS ? 'green' : '#236fb8' }">{{ tableElem.updateDHS ?
          'check_circle' : 'cancel' }}</mat-icon>
        <mat-icon [ngStyle]="{ color: tableElem.allowRemoteDesktopAccess ? 'green' : '#236fb8' }">{{
          tableElem.allowRemoteDesktopAccess ? 'check_circle' : 'cancel'
          }}</mat-icon>
        <mat-icon [ngStyle]="{ color: tableElem.trainingDevice ? 'green' : '#236fb8' }">{{
          tableElem.trainingDevice ? 'check_circle' : 'cancel'
          }}</mat-icon>
      </div>
    </ng-container>
  </div>
</ng-template>