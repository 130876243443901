import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DownloadableContentDelivery } from 'src/app/device/interfaces/DownloadableContentDelivery';
import { PagedResponse } from 'src/app/shared/interfaces/PagedResponse';
import { createHttpParams } from 'src/app/utils/http-utils';
import { environment } from 'src/environments/environment';
import { Device } from '../interfaces/Device';
import { DeviceActivity } from '../interfaces/DeviceActivity';
import { DeviceHealthReport } from '../interfaces/DeviceHealthReport';
import { DeviceListResponse } from '../interfaces/DeviceListResponse';
import { DeviceUpdate } from '../interfaces/DeviceUpdate';
import { DownloadableContentForDevice } from '../interfaces/DownloadableContent';
import { UpdateDeviceTypeData } from '../interfaces/UpdateDeviceTypeData';

@Injectable({
  providedIn: 'root',
})
export class DeviceApiService {
  constructor(private httpClient: HttpClient) { }

  getDevice(deviceId: string, version?: 'v1' | 'v2'): Observable<Device> {
    const url = `${environment.awsApi.apiBaseUrl}/devices/${deviceId}`;
    return this.httpClient.get<Device>(encodeURI(url));
  }

  // gets the device list to populate the device list UI table
  getDeviceList(
    params: {
      searchText?: string;
      searchSerialNumbers?: string;
      deviceId?: string;
      deviceTypes?: string[];
      type?: string;
      status?: string;
      serialNumber?: string;
      assignedDlcUuid?: string;
      notAssignedDlcUuid?: string;
      pageSize?: number;
      pageIndex?: number;
      sortColumn?: string;
      sortDirection?: string;
    },
    version?: 'v1' | 'v2'
  ): Observable<DeviceListResponse> {
    return this.httpClient.get<DeviceListResponse>(`${environment.awsApi.apiBaseUrl}/devices`, {
      params: createHttpParams({
        searchTexts: params.searchText,
        searchSerialNumbers: params.searchSerialNumbers,
        deviceId: params.deviceId,
        deviceType: params.deviceTypes,
        type: params.type,
        status: params.status,
        serialNumber: params.serialNumber,
        assignedDlcUuid: params.assignedDlcUuid,
        notAssignedDlcUuid: params.notAssignedDlcUuid,
        page: params.pageIndex,
        size: params.pageSize,
        sortColumn: params.sortColumn,
        sortDirection: params.sortDirection,
      }),
    });
  }

  //--- START:  new lifcycle ---

  provisionDevice(deviceType: string, serialNumber: string, note: string, updateDHS: boolean, allowRemoteDesktopAccess: boolean, trainingDevice: boolean) {
    return this.httpClient.post<Device>(
      encodeURI(`${environment.awsApi.apiBaseUrl}/devices`),
      {
        deviceType,
        serialNumber,
        note,
        updateDHS,
        allowRemoteDesktopAccess,
        trainingDevice,
      },
      {}
    );
  }

  attachDevice(parentDeviceId: string, deviceId: string) {
    return this.httpClient.put(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${parentDeviceId}/attached/${deviceId}`), {});
  }

  dettachDevice(deviceId: string) {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/attached/${deviceId}`), {});
  }

  connectDevice(deviceId: string) {
    return this.httpClient.put(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/connected`), {});
  }

  cancelDevice(deviceId: string) {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/connected`), {});
  }

  disconnectDevice(deviceId: string) {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/connected`), {});
  }

  blockDevice(deviceId: string) {
    return this.httpClient.put(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/blocked`), {});
  }

  unblockDevice(deviceId: string) {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/blocked`), {});
  }

  decommissionDevice(deviceId: string) {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}`), {});
  }

  reactivateRegId(deviceId: string) {
    return this.httpClient.patch(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/reactivation`), {});
  }

  // --- END: new lifcycle ---

  updateDevice(device: DeviceUpdate, version?: 'v1' | 'v2'): Observable<Device> {
    return this.httpClient.put<Device>(`${environment.awsApi.apiBaseUrl}/devices/${device.id}`, {
      updateDHS: device.updateDHS,
      allowRemoteDesktopAccess: device.allowRemoteDesktopAccess,
      note: device.note,
    });
  }

  getDevicesWithDownloadableContent(
    downloadableContentUuid: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string,
    searchText?: string
  ): Observable<DeviceListResponse> {
    return this.httpClient.get<DeviceListResponse>(`${environment.awsApi.apiBaseUrl}/devices`, {
      params: createHttpParams({
        assignedDlcUuid: downloadableContentUuid,
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        searchText: searchText,
      }),
    });
  }

  getDevicesWithoutDownloadableContent(
    downloadableContentUuid: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string,
    searchText?: string
  ): Observable<DeviceListResponse> {
    return this.httpClient.get<DeviceListResponse>(`${environment.awsApi.apiBaseUrl}/devices`, {
      params: createHttpParams({
        notAssignedDlcUuid: downloadableContentUuid,
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        searchText: searchText,
      }),
    });
  }
  // gets the device list to populate the device list UI table
  getSatelliteDeviceList(searchText: string, pageSize: number, pageIndex: number, sortColumn: string, sortDirection: string): Observable<DeviceListResponse> {
    return this.httpClient.get<DeviceListResponse>(`${environment.awsApi.apiBaseUrl}/devices`, {
      params: createHttpParams({
        type: 'satellites',
        searchText: searchText,
        size: pageSize,
        page: pageIndex,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      }),
    });
  }

  // provisions a new device
  createDevice(deviceType: string, serialNumber: string, note: string, isUnconnectedDevice: boolean, updateDHS?: boolean): Observable<Device> {
    return this.httpClient.post<Device>(`${environment.awsApi.apiBaseUrl}/devices`, {
      deviceType,
      serialNumber,
      note,
      isUnconnectedDevice,
      updateDHS,
    });
  }
  // quarantine or delete device
  quarantineDevice(deviceId: string): Observable<unknown> {
    return this.httpClient.post(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/quarantine`), null);
  }

  deleteRegistration(registrationId: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/registrations/${registrationId}`));
  }

  deleteDevice(deviceId: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}`));
  }

  // deregister Satellite Device
  deregisterSatelliteDevice(connectedDeviceId: string, satId: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${connectedDeviceId}/satellites/${satId}`));
  }

  // re-activate device
  reactivateDevice(deviceId: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/quarantine`));
  }
  getDeviceHealthReport(deviceId: string): Observable<DeviceHealthReport | null> {
    return this.httpClient.get<DeviceHealthReport>(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/health`)).pipe(
      map((resp) => {
        if (!resp) {
          return null;
        }
        return resp;
      })
    );
  }

  deliverDownloadableContent(fileUuid: string, downloadableContentDelivery: DownloadableContentDelivery, caseNumber: string | null): Observable<unknown> {
    let httpHeaders = new HttpHeaders();
    if (caseNumber) {
      httpHeaders = httpHeaders.append('caseNumber', caseNumber);
    }

    return this.httpClient.post(`${environment.awsApi.apiBaseUrl}/downloadableContent/${fileUuid}/delivery`, downloadableContentDelivery, {
      headers: httpHeaders,
    });
  }

  revokeDownloadableContentDelivery(fileUuid: string, deviceSn: string, deviceType: string, caseNumber: string | null): Observable<unknown> {
    let httpheaders = new HttpHeaders();
    if (caseNumber) {
      httpheaders = httpheaders.append('caseNumber', caseNumber);
    }

    return this.httpClient.delete(`${environment.awsApi.apiBaseUrl}/downloadableContent/${fileUuid}/delivery`, {
      params: createHttpParams({ deviceSn, deviceType }),
      headers: httpheaders,
    });
  }

  addDeviceType(
    deviceTypeName: string, connectedDeviceType: string, satelliteDeviceType: boolean, updateDhs: boolean, unpackUploadedArchives: boolean, sMaxMatch: boolean, gateKeeperConnection: boolean, quickConnection: boolean, specialProcessingPattern: string): Observable<unknown> {
    const params = createHttpParams({ connectedDeviceType });
    return this.httpClient.post(
      `${environment.awsApi.apiBaseUrl}/devices/types`,
      {
        deviceType: deviceTypeName,
        updateDhs,
        satelliteDeviceType,
        unpackUploadedArchives,
        sMaxMatch,
        gateKeeperConnection,
        quickConnection,
        specialProcessingPattern
      },
      {
        params,
      }
    );
  }

  updateDeviceType(deviceTypeId: number, data: UpdateDeviceTypeData): Observable<unknown> {
    return this.httpClient.put(`${environment.awsApi.apiBaseUrl}/devices/types/${deviceTypeId}`, {
      ...data,
    });
  }

  getDownloadableContentPerDevice(
    searchText: string,
    deviceId: string,
    pageSize: number,
    pageIndex: number,
    sortColumn: string,
    sortDirection: string
  ): Observable<PagedResponse<DownloadableContentForDevice>> {
    const params = createHttpParams({
      searchText,
      size: pageSize,
      page: pageIndex,
      sortColumn,
      sortDirection,
    });

    return this.httpClient.get<PagedResponse<DownloadableContentForDevice>>(`${environment.awsApi.apiBaseUrl}/downloadableContent/devices/${deviceId}`, {
      params,
    });
  }

  getDeviceActivityLog(
    deviceId: string,
    activityType: string | null,
    from: Date | null,
    until: Date | null,
    range: number | null,
    limit: number | null
  ): Observable<DeviceActivity[]> {
    return this.httpClient
      .get<DeviceActivity[]>(`${environment.awsApi.apiBaseUrl}/devices/${deviceId}/logs`, {
        params: createHttpParams({
          deviceActivity: activityType,
          from: from ? from.toISOString() : null,
          until: until ? until.toISOString() : null,
          range,
          limit,
        }),
      })
      .pipe(map((activities) => activities.map((activity) => ({ ...activity, timestamp: new Date(activity.timestamp) }))));
  }

  deleteDeviceTypeMapping(connectedDeviceType: string, satelliteDeviceType: string): Observable<unknown> {
    return this.httpClient.patch(
      encodeURI(`${environment.awsApi.apiBaseUrl}/devices/types/${connectedDeviceType}/satelliteTypes/${satelliteDeviceType}`),
      null
    );
  }

  // deletes a device type
  deleteDeviceType(deviceTypeId: number, deviceType: string): Observable<unknown> {
    return this.httpClient.delete(encodeURI(`${environment.awsApi.apiBaseUrl}/devices/types/${deviceTypeId}`), {
      params: createHttpParams({ deviceType }),
    });
  }

  newDevicecycleHeader(headers: HttpHeaders): HttpHeaders {
    return headers.append('x-api-version', 'new-lifecycle');
  }
}
